<template>
  <div>
    <v-dialog
      :value="$root.deleteModal.status"
      persistent
      max-width="550px"
      transition="dialog-transition"
      overlay-opacity="0.2"
    >
      <v-card class="round-borders">
        <v-card-title class="pt-6 pb-0">{{
          $root.deleteModal.title
        }}</v-card-title>
        <v-card-text class="py-0">
          <p class="darkGrey--text text--lighten-3 subtitle-1">
            {{ $root.deleteModal.description }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel" color="darkGrey" text class="text-capitalize"
            >Cancel</v-btn
          >
          <v-btn
            @click="confirmDelete"
            elevation="0"
            color="errorButton white--text"
            class="text-capitalize px-5"
            >{{ $root.deleteModal.buttonText }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  methods: {
    async confirmDelete() {
      let response
      try {
        response = await this.$root.deleteModal.callback()
      } catch (error) {
        console.log(error)
        this.$root.deleteModal.status = false
        this.cancel()
        return this.$root.$emit(this.$root.deleteModal.eventName, { error: error })
      }
      this.$root.$emit(this.$root.deleteModal.eventName, { response })
      this.$root.deleteModal.status = false
      this.cancel()
    },
    cancel() {
      this.$root.deleteModal.status = false
      this.$root.deleteModal = {
        title: '',
        description: '',
        callback: null,
        buttonText: '',
        eventName: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.round-borders {
  border-radius: 10px;
}
</style>
